@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'efnzepsm';
  src: local('efnzepsm'), url('assets/fonts/efnzepsm.woff') format('woff');
}

body {
  font-family: sans-serif;
  background-color: #ddd;
  background-image: url('assets/manos.jpg');
  background-position: center;
  background-size: auto;
  background-repeat: repeat;
  width: 100vw;
  height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'efnzepsm';
}

h1, h2 {
  text-align: center;

  img {
    width: 50%;
  }
}

h1 {
  font-size: 4em;
  font-weight: bold;
  padding-top: 0.5em;
  text-transform: uppercase;
}

footer {
  font-size: 1.1em;
  margin-bottom: 3em;
  text-align: center;

  &.row {
    margin-top: 3em;
  }

  .hashtags {
    font-weight: bold;
    margin-right: 1em;
  }

  a {
    text-decoration: underline;
    color: #000;
  }

  .made-by {
    font-size: 0.7em;
  }

  .disclaimer {
    font-size: 0.6em;
  }

  .organizaciones {
    a {
      font-size: 1em;
      font-weight: bold;
    }

    .sep {
      margin-left: 1em;
      margin-right: 1em;
    }
  }
}

.title {
  width: 100%;
  text-align: center;
  display: block;
}

.slogan {
  margin-top: 0.6em;
  text-align: center;
}

.sticker-button {
  width: 82px !important;
  height: 82px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  text-align: center;
  padding: 0px;
  margin-bottom: 10px;
  border: none;
  background: none;

  img {
    width: 80%;
    filter: drop-shadow(1px 1px 5px black);
    -webkit-filter: drop-shadow(1px 1px 1px black);
  }
}

.panel {
  padding: 1em;
}

p {
    font-size: 0.8em;
}

.dropzone {
  button {
    background-color: #df1750;
    border: none;
  }
}

button.share {
  margin-right: 0.5em;
}
